import Dunloop from "./Dunloop";
import image from "./assests/image.jpeg";
const App = () => {
  return (
    <div className="App">
      <div className="w-full h-[500px]">
        <img src={image} className="w-full h-full object-cover" />
      </div>
      <Dunloop />
    </div>
  );
};

export default App;
