import { useState } from "react";
// UI
import Container from "./components/Container/Container";
import Button from "./components/Button";
import Spinner from "./components/Spinner";
// form components
import useInput from "./form/Components/Hooks/user-input";
import Input from "./form/Components/Inputs/Input";
import inputErrorMessage from "./form/Components/Messages/inputErrorMessage";

const WarrantyForm = () => {
  const inputStyle =
    "w-full border border-gray-500 rounded-[4px] p-3 text-font placeholder:text-sm bg-white";
  const [isLoading, setIsLoading] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [isFormError, setIsFormError] = useState(false);
  const [isFormSuccess, setIsFormSuccess] = useState(false);
  const [dateIsFocus, setDateIsFocus] = useState(false);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 300,
      behavior: "smooth",
    });
  };

  const clearErrorHandler = () => {
    setIsFormSuccess(false);
    setSubmitClicked(false);
  };

  const dateFocusHandler = () => {
    setDateIsFocus(true);
  };

  const {
    value: firstNameInput,
    isValid: firstNameIsValid,
    isTouched: firstNameIsTouched,
    HasError: firstNameHasError,
    inputChangeHandler: firstNameChangeHandler,
    inputBlurHandler: firstNameBlurHanlder,
    reset: firstNameReset,
  } = useInput((value) => /^[a-zA-Z]+$/.test(value));

  const {
    value: lastNameInput,
    isValid: lastNameIsValid,
    isTouched: lastNameIsTouched,
    HasError: lastNameHasError,
    inputChangeHandler: lastNameChangeHandler,
    inputBlurHandler: lastNameBlurHanlder,
    reset: lastNameReset,
  } = useInput((value) => /^[a-zA-Z]+$/.test(value));

  const {
    value: phoneNumberInput,
    isValid: phoneNumberIsValid,
    isTouched: phoneNumberIsTouched,
    HasError: phoneNumberHasError,
    inputChangeHandler: phoneNumberChangeHandler,
    inputBlurHandler: phoneNumberBlurHanlder,
    reset: phoneNumberReset,
  } = useInput((value) => value.trim("") !== "");

  const {
    value: emailInput,
    isValid: emailIsValid,
    isTouched: emailIsTouched,
    HasError: emailHasError,
    inputChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHanlder,
    reset: emailReset,
  } = useInput((value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  });

  const {
    value: dateOfBirthInput,
    isValid: dateOfBirthIsValid,
    isTouched: dataOfBirthIsTouched,
    HasError: dateOfBirthHasError,
    inputChangeHandler: dateOfBirthChangeHandler,
    inputBlurHandler: dateOfBirthBlurHanlder,
    reset: dateOfBirthReset,
  } = useInput((value) => /^\d{4}-\d{2}-\d{2}$/.test(value));

  const {
    value: serialNumberInput,
    isValid: serialNumberIsValid,
    isTouched: serialNumberIsTouched,
    HasError: serialNumberHasError,
    inputChangeHandler: serialNumberChangeHandler,
    inputBlurHandler: serialNumberBlurHanlder,
    reset: serialNumberReset,
  } = useInput((value) => value.trim("") !== "");

  const {
    value: carNameInput,
    isValid: carNameIsValid,
    isTouched: carNameIsTouched,
    HasError: carNameHasError,
    inputChangeHandler: carNameChangeHandler,
    inputBlurHandler: carNameBlurHanlder,
    reset: carNameeset,
  } = useInput((value) => value.trim("") !== "");

  const {
    value: carModelInput,
    isValid: carModelIsValid,
    isTouched: carModelIsTouched,
    HasError: carModelHasError,
    inputChangeHandler: carModelchangeHandler,
    inputBlurHandler: carModelBlurHanlder,
    reset: carModelReset,
  } = useInput((value) => value.trim("") !== "");

  const {
    value: carProductionDateInput,
    isValid: carProductionDateIsValid,
    isTouched: carProductionDateIsTouched,
    HasError: carProductionDateHasError,
    inputChangeHandler: carProductionDatehangeHandler,
    inputBlurHandler: carProductionDateBlurHanlder,
    reset: carProductionDateReset,
  } = useInput((value) => /^\d{4}$/.test(value));

  const {
    value: carPlateInput,
    isValid: carPlateIsValid,
    isTouched: carPlateIsTouched,
    HasError: carPlateHasError,
    inputChangeHandler: carPlatechangeHandler,
    inputBlurHandler: carPlateBlurHanlder,
    reset: carPlateReset,
  } = useInput((value) => /^\d{2,}$/.test(value));

  // font tiers info
  const [sectionWidth, setSectionWidth] = useState("");
  const [ratioValue, setRatioValue] = useState("");
  const [rimSizeValue, setRimSizeValue] = useState("");
  const [patternValue, setPatternValue] = useState("");
  // Rear tier info
  const [rearSectionWidth, setRearSectionWidth] = useState();
  const [rearRatioValue, setRearRatioValue] = useState();
  const [rearRimSize, setRearRimSizeValue] = useState();

  // state for check box
  const [sameSize, setSameSize] = useState(false);
  const [inputError, setInputError] = useState(false);
  //  dealer name amd plate code
  const [plateCode, setPlateCode] = useState("");
  const [dealderName, setDealerName] = useState();

  // handler for front tiers
  const handleSectionWidth = (event) => {
    setSectionWidth(event.target.value);
  };
  const handleRatio = (event) => {
    setRatioValue(event.target.value);
  };
  const handleRimSize = (event) => {
    setRimSizeValue(event.target.value);
  };
  const handlePattern = (event) => {
    setPatternValue(event.target.value);
  };

  // handler for rear tiers
  const handleRearSectionWidth = (event) => {
    setRearSectionWidth(event.target.value);
  };
  const handleRearRatio = (event) => {
    setRearRatioValue(event.target.value);
  };
  const handleRearRimSize = (event) => {
    setRearRimSizeValue(event.target.value);
  };

  // dealer name amd plate code handler
  const handleDealerName = (event) => {
    setDealerName(event.target.value);
  };

  const handlePlateCode = (event) => {
    setPlateCode(event.target.value);
  };

  // handle check box
  const handleSameSizeChange = (event) => {
    setSameSize(event.target.checked);
    if (!sectionWidth || !ratioValue || !rimSizeValue) {
      setInputError(true);
      return;
    }
    if (event.target.checked) {
      setRearSectionWidth(sectionWidth);
      setRearRatioValue(ratioValue);
      setRearRimSizeValue(rimSizeValue);
      setInputError(false);
    }
  };

  const names = [
    "Abdo Group",
    "Abdo Robert Zgheib & Co Sarl",
    "Afif Nabbout Sons Co S.A.R.L",
    "Andre Saliba",
    "Ayash Steel",
    "Bakarji Trading S.A.R.L",
    "Beyh Sarl",
    "Century Motor CO",
    "Chamoun For Tires Trading",
    "Chamoun Trdg Co S.A.R.L",
    "Electro Abou Assi",
    "Elie & Sami Sakr Co",
    "Elie Georges Feghali",
    "Ets Aref Abi Said",
    "Ets Charbel Amine Bou Mansour Pour le Commerce",
    "Ets Daraawi",
    "Ets Hussein Mhammad Hussein Faour",
    "Ets Maroun Nasr",
    "Ets Mohamad Jaafar Jaafar For Trading",
    "Ets Raymond Abou Rjeily",
    "Ets. Al Daraawi",
    "Faour Group Tradings  S.A.R.L",
    "First International Est.",
    "Gabriel Abou Adal & Co. SAL",
    "Garage El Sayegh",
    "Garage Robert Abi Zeid",
    "Ghazal Tyres sarl",
    "Gilbert Felix Yaghi",
    "Heald Trading Company SAL",
    "H.N For General Trading",
    "Houssam Haidar And Partners",
    "IMPEX TRADING CO.SAL",
    "J.F Eid Trading",
    "Joseph Chbib Elzoghbi",
    "JOSEPH EL-ARAYA",
    "Khoury Tires Company S.A.R.L",
    "Louay & Rachad Moucharafieh Co",
    "Michel Nasr",
    "Michel Toni Matta",
    "Million Rent a Car s.a.r.l",
    "Moussallem Tires",
    "Nasser For Tires",
    "Nasseredin Trading Ets",
    "Nicolas Abdel Karim Aramouni stores",
    "Pierre Howayek pour le Commerce, Ets",
    "Rasamny Younes Motor Co SAL",
    "Rkein",
    "Saad & Trad Co",
    "Sfeir Trading",
    "Sigma M.E S.A.L",
    "Societe Asaad Gabriel & Fils",
    "Soft Tires S.A.R.L",
    "Sons Nemer Bou Khalil S.A.R.L",
    "Stop & Go",
    "STATION EL SAIDE",
    "Ste Wehbe Group",
    "Store Abou Adnan Taleb Al-Danab for tires",
    "T.Gargour &  Fils S.A.L",
    "Taleb Tires SARL",
    "Tire Up s.a.r.l",
    "Tire Zone",
    "Tiresmart S.A.L",
    "Toni Kasouf",
    "Walid Jamal El-Zaatari",
    "Zaarour Tyres Co.",
    "Zabbat Trading Co S.A.R.L",
    "Ziad Tannous Al Aam",
    "Ziadeh Group SARL",
    "ziadeh trading SARL",
    "Hassan Al-Sabbagh and Partners ",
  ];

  let formIsValid =
    firstNameIsValid &&
    lastNameIsValid &&
    phoneNumberIsValid &&
    serialNumberIsValid &&
    carNameIsValid &&
    carModelIsValid &&
    carProductionDateIsValid &&
    carPlateIsValid &&
    plateCode &&
    sectionWidth &&
    ratioValue &&
    rimSizeValue &&
    patternValue &&
    rearSectionWidth &&
    rearRatioValue &&
    rearRimSize &&
    dealderName;

  const formSubmitHandler = async (event) => {
    event.preventDefault();

    if (!formIsValid) {
      setSubmitClicked(true);
      return;
    }

    const dunlopData = {
      firstName: firstNameInput,
      lastName: lastNameInput,
      phoneNumber: phoneNumberInput,
      cardNumber: serialNumberInput,
      carBrand: carNameInput,
      carModel: carModelInput,
      carProductionYear: carProductionDateInput,
      plateNumber: carPlateInput,
      platecode: plateCode,
      dealer_name: dealderName,
      frontTiersInfo: [
        {
          sectionWidth: sectionWidth,
          ratio: ratioValue,
          rimSize: rimSizeValue,
          pattern: patternValue,
        },
      ],
      rearTiersInfo: [
        {
          sectionWidth: rearSectionWidth,
          ratio: rearRatioValue,
          rimSize: rearRimSize,
        },
      ],
    };

    console.log(dunlopData);

    try {
      setIsLoading(true);
      const response = await fetch(
        "https://api.dunloptireslebanon.com/public/api/dunlop",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dunlopData),
        }
      );

      if (!response.ok) {
        setIsFormError(true);
        setIsLoading(false);
        handleScrollToTop();
        console.log(response);
      } else {
        const data = await response.json();
        setIsFormSuccess(true);
        setIsLoading(false);
        handleScrollToTop();
        console.log(data);
      }
    } catch (error) {
      console.error(error.message);
    }

    firstNameReset();
    lastNameReset();
    phoneNumberReset();
    dateOfBirthReset();
    emailReset();
    serialNumberReset();
    carNameeset();
    carModelReset();
    carProductionDateReset();
    carPlateReset();

    // set
    setSectionWidth("");
    setRatioValue("");
    setRimSizeValue("");
    setPatternValue("");
    setPlateCode("");
    setRearSectionWidth("");
    setRearRatioValue("");
    setRearRimSizeValue("");
    setDealerName("");
    setSameSize("");
  };

  return (
    <main className="bg-gray-100">
      <Container>
        <form
          onSubmit={formSubmitHandler}
          className="py-10 lg:px-10 lg:py-20 flex flex-col gap-2"
        >
          <h1 className="text-3xl font-medim text-center mb-4">
            Dunlop warranty Form
          </h1>
          {isFormError && (
            <div className="flex flex-col md:flex-row text-center items-center justify-center gap-x-2 pb-4 text-lg text-red-500">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="#ef4444"
                width="24px"
                height="24px"
              >
                <path d="M 6.7070312 4.2929688 L 4.2929688 6.7070312 L 11.585938 14 L 4.2929688 21.292969 L 6.7070312 23.707031 L 14 16.414062 L 21.292969 23.707031 L 23.707031 21.292969 L 16.414062 14 L 23.707031 6.7070312 L 21.292969 4.2929688 L 14 11.585938 L 6.7070312 4.2929688 z " />
              </svg>

              <p className="">Form submission failed. Please try again</p>
            </div>
          )}

          {isFormSuccess && (
            <div className="flex flex-col md:flex-row text-center items-center justify-center gap-x-2 pb-4 text-lg text-green-600">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="green"
                width="24px"
                height="24px"
              >
                <path d="M0 0h24v24H0z" fill="none" />
                <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
              </svg>
              <p className="  ">
                Thank you for submitting the form! Your information has been
                successfully received
              </p>
            </div>
          )}

          <div className="flex flex-col md:flex-row items-center gap-y-2 md:gap-x-4">
            <Input
              boxStyle={"w-full"}
              inputStyle={inputStyle}
              type={"text"}
              id={"firstName"}
              value={firstNameInput}
              placeholder="First Name (الاسم الأول)"
              onChange={(event) => {
                firstNameChangeHandler(event);
                clearErrorHandler();
              }}
              onBlur={firstNameBlurHanlder}
              hasError={
                firstNameHasError || (!firstNameIsTouched && submitClicked)
              }
              errorMessage={inputErrorMessage.text}
            />

            <Input
              boxStyle={"w-full"}
              inputStyle={inputStyle}
              type={"text"}
              id={"lastName"}
              value={lastNameInput}
              placeholder="Last Name (اسم العائلة)"
              onChange={(event) => {
                lastNameChangeHandler(event);
                clearErrorHandler();
              }}
              hasError={
                lastNameHasError || (!lastNameIsTouched && submitClicked)
              }
              onBlur={lastNameBlurHanlder}
              errorMessage={inputErrorMessage.text}
            />
          </div>

          <div className="flex flex-col md:flex-row items-center gap-y-2 md:gap-x-4">
            <Input
              boxStyle={"w-full"}
              inputStyle={inputStyle}
              type={"number"}
              id={"phoneNumber"}
              value={phoneNumberInput}
              placeholder="Phone Number (e.g. 03000000) / (رقم الهاتف - مثال: ٠٣٠٠٠٠٠٠)"
              onChange={(event) => {
                phoneNumberChangeHandler(event);
                clearErrorHandler();
              }}
              hasError={
                phoneNumberHasError || (!phoneNumberIsTouched && submitClicked)
              }
              onBlur={phoneNumberBlurHanlder}
              errorMessage={inputErrorMessage.text}
            />
            <Input
              boxStyle={"w-full"}
              inputStyle={inputStyle}
              type={"email"}
              id={"email"}
              value={emailInput}
              placeholder="Email Address (e.g. john@example.com)  (البريد الإلكتروني)"
              onChange={(event) => {
                emailChangeHandler(event);
                clearErrorHandler();
              }}
              hasError={emailHasError || (!emailIsTouched && submitClicked)}
              onBlur={emailBlurHanlder}
              errorMessage={inputErrorMessage.email}
            />
          </div>

          <span className={""}>
            <input
              min="1950-01-01"
              max="2020-12-31"
              className={inputStyle}
              id={"dateOfBirth"}
              type={`${dateIsFocus ? "date" : "text"}`}
              name={"name"}
              placeholder="Date of Birth (e.g. 2000-01-10) تاريخ الولادة (على سبيل المثال 2000-01-10)"
              value={dateOfBirthInput}
              onChange={(event) => {
                dateOfBirthChangeHandler(event);
                clearErrorHandler();
              }}
              onBlur={dateOfBirthBlurHanlder}
              onFocus={dateFocusHandler}
            />
            <p
              className={`text-red-500 text-sm my-1 ${
                dateOfBirthHasError ? " opacity-1 " : " opacity-0"
              }`}
            >
              {inputErrorMessage.text}
            </p>
          </span>

          <Input
            boxStyle={"w-full"}
            inputStyle={inputStyle}
            type={"number"}
            id={"serialNumber"}
            value={serialNumberInput}
            placeholder="Card Number (رقم البطاقة)"
            onChange={(event) => {
              serialNumberChangeHandler(event);
              clearErrorHandler();
            }}
            hasError={
              serialNumberHasError || (!serialNumberIsTouched && submitClicked)
            }
            onBlur={serialNumberBlurHanlder}
            errorMessage={inputErrorMessage.text}
          />
          <div className="flex flex-col md:flex-row items-center gap-y-2 md:gap-x-4">
            <Input
              boxStyle={"w-full"}
              inputStyle={inputStyle}
              type={"text"}
              id={"carName"}
              value={carNameInput}
              placeholder="&#x202A;Car Brand (ماركة السيارة)&#x202C;"
              onChange={(event) => {
                carNameChangeHandler(event);
                clearErrorHandler();
              }}
              hasError={carNameHasError || (!carNameIsTouched && submitClicked)}
              onBlur={carNameBlurHanlder}
              errorMessage={inputErrorMessage.text}
            />
            <Input
              boxStyle={"w-full"}
              inputStyle={inputStyle}
              type={"text"}
              id={"carModel"}
              value={carModelInput}
              placeholder="Car Model (طراز السيارة)"
              onChange={(event) => {
                carModelchangeHandler(event);
                clearErrorHandler();
              }}
              hasError={
                carModelHasError || (!carModelIsTouched && submitClicked)
              }
              onBlur={carModelBlurHanlder}
              errorMessage={inputErrorMessage.text}
            />
          </div>

          <Input
            boxStyle={"w-full"}
            inputStyle={inputStyle}
            type={"text"}
            id={"carProduction"}
            value={carProductionDateInput}
            placeholder="Car Production Year (e.g. 2022) سنة إنتاج السيارة (ع.م 2022)"
            onChange={(event) => {
              carProductionDatehangeHandler(event);
              clearErrorHandler();
            }}
            hasError={
              carProductionDateHasError ||
              (!carProductionDateIsTouched && submitClicked)
            }
            onBlur={carProductionDateBlurHanlder}
            errorMessage={inputErrorMessage.text}
          />

          <div className="flex flex-col md:flex-row items-center gap-y-2 md:gap-x-4">
            <Input
              boxStyle={"w-full"}
              inputStyle={inputStyle}
              type={"text"}
              id={"carPlate"}
              value={carPlateInput}
              placeholder="Plate Number (رقم اللوحة)"
              onChange={(event) => {
                carPlatechangeHandler(event);
                clearErrorHandler();
              }}
              hasError={
                carPlateHasError || (!carPlateIsTouched && submitClicked)
              }
              onBlur={carPlateBlurHanlder}
              errorMessage={inputErrorMessage.text}
            />

            <span className="w-full">
              <select
                className={inputStyle}
                id="plate-code"
                value={plateCode}
                onChange={handlePlateCode}
              >
                <option value="" disabled>
                  -- Plate Code (رمز المنطقة)--
                </option>
                <option value="a">A</option>
                <option value="b">B</option>
                <option value="z">Z</option>
                <option value="s">S</option>
                <option value="y">Y</option>
                <option value="g">G</option>
                <option value="o">O</option>
                <option value="n">N</option>
                <option value="t">T</option>
                <option value="d">D</option>
                <option value="j">J</option>
                <option value="m">M</option>
                <option value="r">R</option>
              </select>
              <p
                className={`text-red-500 text-sm my-1 ${
                  submitClicked && !plateCode ? "opacity-100" : "opacity-0"
                }`}
              >
                Please select Plate Code
              </p>
            </span>
          </div>

          {/* front tiers info */}
          <h1 className="text-center text-lg md:text-xl lg:text-2xl text-font font-semibold mb-2 mt-2 ">
            &#x202A;Front Tires information (معلومات الإطارات الأمامية)&#x202C;
          </h1>

          <div className="flex flex-col lg:flex-row items-center gap-y-2 md:gap-x-4">
            {/*  */}
            <span className="w-full">
              <select
                className={inputStyle}
                id="my-select"
                value={sectionWidth}
                onChange={handleSectionWidth}
              >
                <option value="" disabled>
                  -- Section Width (عرض الجزء) --
                </option>
                {[...Array(18)].map((_, i) => (
                  <option key={i} value={155 + i * 10}>
                    {155 + i * 10}
                  </option>
                ))}
              </select>
              <p
                className={`text-red-500 text-sm my-1 ${
                  submitClicked && !sectionWidth ? "opacity-100" : "opacity-0"
                }`}
              >
                Please select section Width
              </p>
            </span>
            <span className="w-full">
              <select
                className={inputStyle}
                id="ratio-width"
                value={ratioValue}
                onChange={handleRatio}
              >
                <option value="" disabled>
                  -- Ratio (النسبة المئوية) --
                </option>
                {[...Array(12)].map((_, i) => (
                  <option key={i} value={30 + i * 5}>
                    {30 + i * 5}
                  </option>
                ))}
              </select>
              <p
                className={`text-red-500 text-sm my-1 ${
                  submitClicked && !ratioValue ? "opacity-100" : "opacity-0"
                }`}
              >
                Please select ratio Value
              </p>
            </span>
            <span className="w-full">
              <select
                className={inputStyle}
                id="rim-size"
                value={rimSizeValue}
                onChange={handleRimSize}
              >
                <option value="" disabled>
                  -- Rim Size (حجم الحافة) --
                </option>
                {[...Array(10)].map((_, i) => (
                  <option key={i} value={13 + i}>
                    {13 + i}
                  </option>
                ))}
              </select>
              <p
                className={`text-red-500 text-sm my-1 ${
                  submitClicked && !rimSizeValue ? "opacity-100" : "opacity-0"
                }`}
              >
                Please select a rim Size
              </p>
            </span>
            <span className="w-full">
              <select
                className={inputStyle}
                value={patternValue}
                onChange={handlePattern}
              >
                <option value=""> -- Pattern (نمط التصميم) --</option>
                <option value="SPFM800">SPFM800</option>
                <option value="sPTOURING-R1">SPTOURING R1</option>
                <option value="SPLM705W">SPLM705W</option>
                <option value="VE303">VE303</option>
                <option value="VE304">VE304</option>
                <option value="MAX050+">MAX050+</option>
                <option value="MAX060+">MAX060+</option>
                <option value="PT3">PT3</option>
                <option value="PT5">PT5</option>
                <option value="DZ102">DZ102</option>
                <option value="ECO300">ECO300+</option>
                <option value="Max050ROF">Max050+ROF</option>

                <option value="PT5A ">PT5A </option>
                <option value="AT5 ">AT5 </option>
                <option value="Max050">Max050</option>
                <option value="TG30">TG30</option>
                <option value="SPRG">SPRG</option>
                <option value="AT3G">AT3G</option>
                <option value="AT22">AT22</option>
                <option value="AT20">AT20</option>
                <option value="AT23">AT23</option>
                <option value="PT21">PT21</option>
                <option value="ST20">ST20</option>
                <option value="AT30">AT30</option>
                <option value="SP01">SP01</option>
                <option value="SP10">SP10</option>
              </select>
              <p
                className={`text-red-500 text-sm my-1 ${
                  submitClicked && !patternValue ? "opacity-100" : "opacity-0"
                }`}
              >
                Please select a pattern
              </p>
            </span>
            {/*  */}
          </div>

          {/* check box */}
          <div className="mb-2 lg:mt-4 lg:mb-7 text-center text-xl text-font">
            <input
              type="checkbox"
              checked={sameSize}
              onChange={handleSameSizeChange}
            />
            <label className="ml-2 text-[16px] text-font">
              Same size for front and rear tires (حجم مطابق للإطارات الأمامية
              والخلفية)
            </label>

            {inputError && (
              <p className="text-sm text-red-500 w-1/2 mx-auto">
                Please select values for section width, ratio, and rim size
                before choosing the 'same size' option (يرجى تحديد قيم لعرض
                الجزء، ونسبة الارتفاع، وحجم الحافة قبل اختيار الخيار 'نفس الحجم'
                )
              </p>
            )}
          </div>

          {/* back tiers */}
          <h1 className="text-center text-lg md:text-xl lg:text-2xl text-font font-semibold mb-2 ">
            Rear Tires information (معلومات الإطارات الخلفية)
          </h1>

          <div className="flex flex-col md:flex-row items-center gap-x-4 mb-2">
            <span className="w-full">
              <select
                className={inputStyle}
                id="rear-section-width"
                value={rearSectionWidth}
                onChange={handleRearSectionWidth}
              >
                <option value="">-- Section Width (عرض الجزء) --</option>
                {[...Array(18)].map((_, i) => (
                  <option key={i} value={155 + i * 10}>
                    {155 + i * 10}
                  </option>
                ))}
              </select>
              <p
                className={`text-red-500 text-sm my-1 ${
                  submitClicked && !rearSectionWidth
                    ? "opacity-100"
                    : "opacity-0"
                }`}
              >
                Please select section width
              </p>
            </span>

            <span className="w-full">
              <select
                className={inputStyle}
                id="rear-ratio-value"
                value={rearRatioValue}
                onChange={handleRearRatio}
              >
                <option value="">-- Ratio (النسبة المئوية) --</option>
                {[...Array(12)].map((_, i) => (
                  <option key={i} value={30 + i * 5}>
                    {30 + i * 5}
                  </option>
                ))}
              </select>
              <p
                className={`text-red-500 text-sm my-1 ${
                  submitClicked && !rearRatioValue ? "opacity-100" : "opacity-0"
                }`}
              >
                Please select ratio value
              </p>
            </span>

            <span className="w-full">
              <select
                className={inputStyle}
                id="rear-rim-size"
                value={rearRimSize}
                onChange={handleRearRimSize}
              >
                <option value=""> -- Rim Size (حجم الحافة) --</option>
                {[...Array(10)].map((_, i) => (
                  <option key={i} value={13 + i}>
                    {13 + i}
                  </option>
                ))}
              </select>
              <p
                className={`text-red-500 text-sm my-1 ${
                  submitClicked && !rearRimSize ? "opacity-100" : "opacity-0"
                }`}
              >
                Please select rim size
              </p>
            </span>
          </div>

          {/* dealder name */}
          <span className="w-full">
            <select
              className={inputStyle}
              value={dealderName}
              onChange={handleDealerName}
            >
              <option value=""> -- Dealer Name (اسم التاجر) --</option>
              {names.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <p
              className={`text-red-500 text-sm my-1 ${
                submitClicked && !dealderName ? "opacity-100" : "opacity-0"
              }`}
            >
              Please select dealer name
            </p>
          </span>

          <div className="flex justify-end">
            <Button
              className={"w-1/4 flex items-center gap-x-2 justify-center"}
            >
              {isLoading && <Spinner />}Submit
            </Button>
          </div>
        </form>
      </Container>
    </main>
  );
};

export default WarrantyForm;
